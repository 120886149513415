@keyframes DragonHover {

  0%,
  100% {
    top: 120px;
    left: 150px;
  }

  50% {
    top: 160px;
    left: 170px;
  }
}


.main-bg {
  top: 0;
  left: 0;
  background: url('/public/images/bg-min.png');
  background-position: center top;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.dragon {
  position: absolute;
  animation: DragonHover 5s linear infinite;
  width: 150px;
  top: 120px;
  left: 150px;
  z-index: 502;
}

.hugo {
  position: fixed;
  width: 20%;
  right: 7%;
  bottom: 15%;
  z-index: 500;
}

.picnic {
  position: fixed;
  width: 75%;
  min-width: 750px;
  left: -10%;
  bottom: 2%;
  z-index: 500;
}

@media screen and (max-width: 1080px) {
  @keyframes DragonHover {

    0%,
    100% {
      top: 35px;
      left: 35px;
    }

    50% {
      top: 45px;
      left: 45px;
    }
  }

  .dragon {
    top: 35px;
    left: 35px;
  }
}

@media screen and (max-width: 480px) {
  @keyframes DragonHover {

    0%,
    100% {
      top: 17px;
      left: 17px;
    }

    50% {
      top: 30px;
      left: 30px;
    }
  }

  .dragon {
    width: 70px;
  }

  .picnic {
    min-width: 500px;
    left: -25%;
  }

  .hugo {
    width: 27%;
  }
}


@media screen and (max-height: 1000px) and (orientation: landscape) {
  .picnic {
    left: -2%;
    width: 800px;
  }

  .hugo {
    width: 220px;
  }
}

@media screen and (max-height: 770px) and (orientation: landscape) {
  .picnic {
    left: -10%;
    width: 800px;
  }
}

@media screen and (max-height: 670px) and (orientation: landscape) {
  @keyframes DragonHover {

    0%,
    100% {
      top: 35px;
      left: 35px;
    }

    50% {
      top: 45px;
      left: 45px;
    }
  }

  .dragon {
    width: 100px;
    top: 35px;
    left: 35px;
  }

  .hugo {
    width: 130px;
  }

  .picnic {
    left: -10%;
    width: 450px;
    min-width: auto;
  }
}
