@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --primary-color: #367b6d;
  --secondary-color: #e7edb5;
  --primary-blur: blur(4px);
}

* {
  font-family: "Bona Nova SC", cursive;
}

.content {
  width: 700px;
  margin: 6rem auto;
  color: var(--primary-color);
  text-align: center;
  position: relative;
  z-index: 501;
}

.h1 {
  font-size: 5rem;
  text-align: center;
  line-height: 5rem;
  margin-bottom: 4rem;
  backdrop-filter: var(--primary-blur);
}

.h1 .baby-borrel-info-button {
  background: none;
  border: none;
  font-size: 5rem;
  color: var(--primary-color);
  position: relative;
}

.h1 .baby-borrel-info-button:hover {
  cursor: pointer;
}

.h1 .baby-borrel-info-button i {
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: -10px;
}

.original-baby-card-viewer-button,
.address-info-button {
  background: none;
  border: none;
  color: var(--primary-color);
  position: relative;
}

.address-info-button i {
  position: absolute;
  font-size: 1.2rem;
  top: -0.4rem;
  right: -0.5rem;
}

.original-baby-card-viewer-button:hover,
.address-info-button:hover {
  cursor: pointer;
}

.react-responsive-modal-modal {
  font-family: "Roboto", cursive;
  text-transform: none;
  color: var(--primary-color);
  border: 10px double var(--primary-color);
  border-radius: 14px;
  line-height: normal;
  font-size: 1.2rem;
}

.react-responsive-modal-modal h2 {
  font-weight: 500;
  margin-bottom: .5rem;
}

.react-responsive-modal-modal p:not(:last-of-type) {
  margin-bottom: .5rem;
}

.react-responsive-modal-modal strong {
  font-weight: 500;
}

.react-responsive-modal-closeButton {
  top: 4px;
  right: 4px;
}

.react-responsive-modal-closeButton svg {
  fill: var(--primary-color)
}

.h2 {
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4rem;
  backdrop-filter: var(--primary-blur);
  display: inline-block;
}

.event-date {
  backdrop-filter: var(--primary-blur);
  font-size: 2rem;
  text-transform: uppercase;
  padding: 1rem 0;
  max-width: 550px;
  margin: 0 auto 4rem auto;
}

.event-date .middle {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-around;
  padding: 1rem 0;
}

.event-date .lined {
  border-top: 3px solid var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
  padding: 1rem 0;
  min-width: 125px;
}

.event-date .day {
  text-align: center;
  font-size: 3.5rem;
}

.location-content,
.address-info-button {
  backdrop-filter: var(--primary-blur);
  font-size: 1.75rem;
}

.frame-border {
  width: calc(100vw - 20px - 4rem);
  height: calc(100vh - 20px - 4rem);
  border: 10px double var(--primary-color);
  border-radius: 14px;
  margin: 2rem;
  position: relative;
}

.map-iframe {
  width: 600px;
  height: 450px;
}

.original-baby-card-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.original-baby-card-viewer-button {
  font-size: 1.5rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 0 .5rem;
}

.original-baby-card-toggle-button,
.original-baby-card-download-link {
  font-family: "Roboto", cursive;
  background: none;
  border: none;
  color: var(--primary-color);
  font-weight: 500;
  padding: 0;
  font-size: inherit;
  text-decoration: underline;
}

.original-baby-card-toggle-button {
  padding-right: .5rem;
}

.original-baby-card-download-link {
  padding-left: .5rem;
}

.original-baby-card-toggle-button:hover {
  cursor: pointer;
}

.original-baby-card-preview {
  border-radius: 14px;
}

@media screen and (max-width: 1080px) {

  .h1,
  .h1 .baby-borrel-info-button {
    font-size: 4rem;
  }

  .content {
    width: 600px;
  }
}

@media screen and (max-width: 776px) {

  .h1,
  .h1 .baby-borrel-info-button {
    line-height: 3rem;
    font-size: 3rem;
  }

  .h2 {
    font-size: 1.5rem;
  }

  .event-date {
    font-size: 1.5rem;
  }

  .event-date .middle {
    font-size: 1rem;
  }

  .event-date .day {
    font-size: 3rem;
  }

  .location-content {
    font-size: 1.5rem;
  }

  .content {
    width: 400px;
  }

  .map-iframe {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {

  .h1,
  .h1 .baby-borrel-info-button {
    line-height: 2rem;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .h2 {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .location-content {
    font-size: 1.2rem;
  }

  .content {
    width: 100%;
    margin: 3rem auto;
  }

  .frame-border {
    width: calc(100vw - 20px - 2rem);
    height: calc(100vh - 20px - 2rem);
    margin: 1rem;
  }

  .h1 .baby-borrel-info-button {
    font-size: 2rem;
  }

  .h1 .baby-borrel-info-button i {
    font-size: 1rem;
  }

  .address-info-button {
    font-size: 1.25rem;
  }

  .address-info-button i {
    font-size: 1rem;
  }

  .event-date {
    font-size: 1.25rem;
    margin: 0 auto 1.5rem auto;
  }

  .event-date .day {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .event-date .lined {
    min-width: 80px;
    border-width: 2px;
  }

  .event-date .middle {
    padding: .75rem 1rem;
  }

  .original-baby-card-viewer,
  .original-baby-card-viewer-button {
    font-size: 1rem;
  }

  .original-baby-card-toggle-button,
  .original-baby-card-download-link {
    font-size: .8rem;
  }

  .original-baby-card-toggle-button,
  .original-baby-card-download-link {
    padding: 0;
  }

  .react-responsive-modal-modal {
    font-size: 1rem;
  }

  .react-responsive-modal-modal h2 {
    margin-bottom: .3rem;
  }

  .react-responsive-modal-modal p:not(:last-of-type) {
    margin-bottom: .3rem;
  }

}

@media screen and (max-height: 1000px) and (orientation: landscape) {

  .content,
  .event-date {
    margin-top: 2rem;
  }

  .h1,
  .h2 {
    margin-bottom: 2rem;
  }

  .h1,
  .h1 .baby-borrel-info-button {
    font-size: 4rem;
    line-height: 4rem;
  }
}

@media screen and (max-height: 770px) and (orientation: landscape) {

  .event-date {
    margin-bottom: .5rem;
    margin-top: 1rem;
  }

  .h1,
  .h2 {
    margin-bottom: 1rem;
  }

  .h1,
  .h1 .baby-borrel-info-button {
    font-size: 4rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-height: 670px) and (orientation: landscape) {
  .heading-break {
    display: none;
  }

  .content {
    margin-top: 1rem;
  }

  .event-date {
    margin-top: 0;
    padding: 0;
  }

  .event-date .middle {
    font-size: 1rem;
    padding: .25rem 4rem;
  }

  .event-date .day {
    font-size: 2.75rem;
  }

  .h1,
  .h1 .baby-borrel-info-button {
    font-size: 2rem;
    line-height: 1.75rem;
  }

  .h1 .baby-borrel-info-button i {
    font-size: 1rem;
  }

  .h2 {
    font-size: 1.25rem;
  }

  .event-date {
    font-size: 1.25rem;
  }

  .location-content,
  .address-info-button {
    font-size: 1.25rem;
  }

  .address-info-button i {
    font-size: .75rem;
  }

  .frame-border {
    width: calc(100vw - 20px - 2rem);
    height: calc(100vh - 20px - 2rem);
    margin: 1rem;
  }

  .map-iframe {
    height: auto;
  }

  .original-baby-card-viewer {
    display: none;
  }
}
